/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../app-context";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import SelectInput from "@cx/ui/SelectInput";
import SearchableSelect from "@cx/ui/SearchableSelect";
import TextInput from "@cx/ui/TextInput";
import NumericInput from "@cx/ui/NumericInput";
import Badge from "@cx/ui/Badge";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import {
  PayTypeOptions,
  RateTypeOptions
} from "../../../../../constants/ModuleConstants";
import Confirmation from "../../../../../commonUtil/dialog/Confirmation";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
import AddPowertrainWarrantyForm from "./AddPowertrainWarrantyForm";
import {  toast } from 'react-toastify';
class AddLaborPricingFormPage extends Component {
  static contextType = AppContext;
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.getVendorList = this.getVendorList.bind(this);
    this.getFuelTypeList = this.getFuelTypeList.bind(this);
    this.addWarranty = this.addWarranty.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.validate = this.validate.bind(this);
    this.isValidForm.bind(this);
    this.initializeLocaleValues();
    const dealerCode = context.dealerCode;
    const { user } = context;
    const { userName } = user;
    const newLaborPricing = {
      ...props.rowRecord,
      dealerCode,
      modUser: userName
    };
    const payTypeOptions = [...PayTypeOptions];
    const rateTypeOptions = [...RateTypeOptions];
    const powertrainWarrantyFields =
      newLaborPricing.dealerLaborPricingRuleId &&
      newLaborPricing.powertrainWarranties
        ? JSON.parse(newLaborPricing.powertrainWarranties)
        : [];

    this.state = {
      newLaborPricing,
      payTypeOptions,
      rateTypeOptions,
      allVendorList: [],
      fuelTypes: [],
      powertrainWarrantyFields,
      dirty: false,
      valid: false,
      showDeleteConfirmation: false,
      errors: { expiryDate: "", fromDate: "" }
    };
  }

  componentDidMount() {
    this.getVendorList();
    this.getFuelTypeList();
    this.updateDesc();
    window.addEventListener(
      "updatePowertrainWarranties",
      this.onUpdatePowertrainWarranties,
      false
    );
    window.addEventListener(
      "removePowertrainWarrantyEvent",
      this.onRemovePowertrainWarrantyEvent,
      false
    );
  }

  onUpdatePowertrainWarranties = event => {
    const { index, field, name } = event.detail;
    const { powertrainWarrantyFields } = this.state;
    powertrainWarrantyFields[index] = field;
    this.setState({ powertrainWarrantyFields });
    this.markDirty(name, true);
  };
  onRemovePowertrainWarrantyEvent = event => {
    const { index } = event.detail;
    const { powertrainWarrantyFields } = this.state;
    powertrainWarrantyFields.splice(index, 1);
    this.setState({ powertrainWarrantyFields });
    this.markDirty(null, true);
  };
  openConfirmationModal = () => {
    const { newLaborPricing } = this.state;
    if (
      newLaborPricing.dealerLaborPricingRuleId &&
      newLaborPricing.payType === "Warranty"
    ) {
      this.setState({ showDeleteConfirmation: true });
    } else {
      this.saveHandler();
    }
  };
  closeDeleteModal = doneDeleting => {
    if (doneDeleting) {
      this.setState({ showDeleteConfirmation: false });
    }
  };
  confirmSaveChanges = () => {
    this.saveHandler();
    this.setState({ showDeleteConfirmation: false });
  };
  initializeLocaleValues() {
    this.saveLabel = xlate("xmm.portal.common.save_button");
    this.addWarrantyLabel = xlate(
      "xmm.portal.pricing_rules.add_powertrain_btn"
    );
    this.addWarrantyOEMTooltip = xlate(
      "xmm.portal.pricing_rules.add_warranty_OEM_tooltip"
    );
    this.addWarrantyExtendedTooltip = xlate(
      "xmm.portal.pricing_rules.add_warranty_extended_tooltip"
    );
    this.saveWarrantyConfirmationMsg = xlate(
      "xmm.portal.pricing_rules.save_powertrain_warranty_msg"
    );
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
  }
  onBlur = name => {
    const valid = this.validate(name);
    this.setState({ valid });
  };
  updateDesc() {
    const { newLaborPricing } = this.state;
    let desc = newLaborPricing.planName;
    let code = "";
    if (newLaborPricing.payType && newLaborPricing.payType === "Customer Pay") {
      code += "C-";
      switch (newLaborPricing.rateType) {
        case "Wholesale":
          code += "WHSE";
          break;
        case "Fleet":
          code += "FLEET";
          break;
        case "Vendor":
          code += "VENDOR";
          break;
        case "Employee":
          code += "EMP";
          break;
        case "Friends & Family":
          code += "F&F";
          break;
        case "Loyal Customer":
          code += "LOYAL";
          break;
      }
    } else if (newLaborPricing.payType === "Warranty") {
      code = "W-";
      switch (newLaborPricing.warrantyType) {
        case "OEM Factory Warranty":
          code += newLaborPricing.planCode? newLaborPricing.planCode: "";
          break;
        case "OEM Extended Warranty":
          code +=  newLaborPricing.planCode? newLaborPricing.planCode: "";
          break;
        case "OEM Maintenance Plan":
          code +=  newLaborPricing.planCode? newLaborPricing.planCode: "";
          break;
      }
    } else if (newLaborPricing.payType === "Service Contract") {
      code = "S-";
      code +=newLaborPricing.planCode? newLaborPricing.planCode: "";
    }
    this.setState({
      newLaborPricing: {
        ...newLaborPricing,
        laborRateDescription: desc,
        code: code
      }
    });
  }
  // changeEvent for textfield
  onChange = event => {
    const { name, value } = event.target;
    const { newLaborPricing } = this.state;
    let newValue;
    switch (name) {
      case "payType":
        newValue = value;
        if (value === "Service Contract") {
          this.setState({
            newLaborPricing: {
              ...newLaborPricing,
              warrantyType: "3rd Party",
              warrantyVendor: "",
              rateType: "",
              warrantyBaseCoverage: "",
              warrantyMileageCoverage: "",
              powertrainWarranties: []
            },
            powertrainWarrantyFields: []
          });
        } else if (value === "Warranty") {
          this.setState({
            newLaborPricing: {
              ...newLaborPricing,
              warrantyType: "",
              warrantyVendor: "",
              rateType: ""
            }
          });
        } else if (value === "Customer Pay") {
          this.setState({
            newLaborPricing: {
              ...newLaborPricing,
              warrantyType: "",
              warrantyVendor: "",
              warrantyBaseCoverage: "",
              warrantyMileageCoverage: "",
              powertrainWarranties: []
            },
            powertrainWarrantyFields: []
          });
        }
        break;
      case "warrantyVendor":
        newValue = value[0] ? value[0].value : "";
        break;
      default:
        newValue = value;
    }
    if (isDifferentValue(newLaborPricing[name], newValue)) {
      this.setState(
        prevState => {
          return {
            newLaborPricing: {
              ...prevState.newLaborPricing,
              [name]: newValue
            }
          };
        },
        () => {
          this.updateDesc();
          this.markDirty(name, true);
        }
      );
    }
  };
  getVendorList() {
    const restUrl = "/ops/proxyapi/ddsproxy/rest/table/warrantyVendor";
    if (this.state.newLaborPricing.make === "ANY"){
      return
    }
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data) {
          this.setState({
            allVendorList: data.map(vendor => ({
              label: vendor.vendor,
              value: vendor.vendor
            }))
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  getFuelTypeList() {
    const {
      newLaborPricing: { dealerCode, make, variant }
    } = this.state;
    if (this.state.newLaborPricing.make === "ANY"){
      return
    }
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/findMetaVehicleDimensionsByMake";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          dealerCode: dealerCode,
          make: make,
          variant: variant,
          countryId: "",
          requestType: "",
          authId: "",
          menuTypeId: "",
          setCategory: "package"
        }
      },
      data => {
        if (data) {
          const list = data.response.detailAttributes
            ? data.response.detailAttributes.detailAttribute
            : null;
          this.setState({
            fuelTypes: list
              .filter(item => item.columnName === "FUEL_TYPE")
              .map(item => ({
                label: item.content,
                value: item.content
              }))
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  createEmptyPowertrainWarranty() {
    const { fuelTypes } = this.state;
    const newWarranty = {
      fuelType: fuelTypes && fuelTypes[0] ? fuelTypes[0].value : "",
      hourlyRate: "",
      baseCoverage: "",
      mileageCoverage: ""
    };
    return newWarranty;
  }
  addWarranty() {
    const newWarranty = this.createEmptyPowertrainWarranty();
    const { powertrainWarrantyFields } = this.state;
    powertrainWarrantyFields.push(newWarranty);
    this.setState({ powertrainWarrantyFields }, () => {
      this.markDirty(name, true);
    });
  }
  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const { valid, errors } = this.validate(fieldName);
      this.setState({ dirty, valid, errors });
      this.props.onModified();
    } else {
      this.setState({ dirty });
    }
  }
  isValidForm() {
    const { newLaborPricing, powertrainWarrantyFields } = this.state;
    let validPowertrainWarranties = true;
    if (!newLaborPricing.skillAStandaloneRate) {
      return false;
    }
    if (
      newLaborPricing.payType === "Warranty" &&
      newLaborPricing.warrantyType === "OEM"
    ) {
      if (!newLaborPricing.warrantyBaseCoverage) {
        return false;
      }
      if (!newLaborPricing.warrantyMileageCoverage) {
        return false;
      }
      powertrainWarrantyFields.forEach(warranty => {
        if (!warranty.hourlyRate) {
          validPowertrainWarranties = false;
        }
        if (!warranty.baseCoverage) {
          validPowertrainWarranties = false;
        }
        if (!warranty.mileageCoverage) {
          validPowertrainWarranties = false;
        }
        if (!warranty.deductible) {
          validPowertrainWarranties = false;
        }
      });
    }
    if (
      newLaborPricing.payType === "Warranty" &&
      newLaborPricing.warrantyType === "Extended"
    ) {     
      powertrainWarrantyFields.forEach(warranty => {
        if (!warranty.hourlyRate) {
          validPowertrainWarranties = false;
        }
        if (!warranty.deductible) {
          validPowertrainWarranties = false;
        }
      });
    }
    if (newLaborPricing.payType === "Warranty" ) { 
      if(!newLaborPricing.warrantyType){
        return false;
      }
    }
    if (newLaborPricing.payType === "Service Contract") {
      if (!newLaborPricing.warrantyVendor) {
        return false;
      }
    }
    if (!validPowertrainWarranties) {
      return false;
    }
    if(!newLaborPricing.planCode || !newLaborPricing.planName){
      return false;
    }
    return true;
  }
  validate(fieldName) {
    let valid = true;
    const { errors, newLaborPricing, powertrainWarrantyFields } = this.state;
    switch (fieldName) {
      case "skillAStandaloneRate": {
        valid = parseFloat(newLaborPricing.skillAStandaloneRate) <= 999;
        errors["skillAStandaloneRate"] = !valid
          ? "The hourly rate cannot be greater than 999"
          : "";
        break;
      }
      case "warrantyBaseCoverage": {
        valid =
          parseFloat(newLaborPricing.warrantyBaseCoverage) <= 99 &&
          parseFloat(newLaborPricing.warrantyBaseCoverage) > 0;
        errors["warrantyBaseCoverage"] = !valid
          ? "The Base coverage cannot be greater than 99 or less than 1"
          : "";
        break;
      }
      case "warrantyMileageCoverage": {
        valid =
          parseFloat(newLaborPricing.warrantyMileageCoverage) <= 999999 &&
          parseFloat(newLaborPricing.warrantyMileageCoverage) > 0;
        errors["warrantyMileageCoverage"] = !valid
          ? "The Mileage coverage cannot be greater than 999999 or less than 1"
          : "";
        break;
      }
      case "planName": {
        //only allow characters and numbers
        const regex = /^[a-zA-Z0-9]*$/;
        //valid = newLaborPricing.planName && regex.test(newLaborPricing.planName) ? true : false 
        errors["planName"] = !(newLaborPricing.planName && regex.test(newLaborPricing.planName))
          ? "The Plan name only allows numbers and characters"
          : "";
        break;
      }           
      case "": {
        powertrainWarrantyFields.forEach(warranty => {
          valid =
            powertrainWarrantyFields.filter(
              item => item.fuelType === warranty.fuelType
            ).length == 1;
          errors["fuelType"] = !valid
            ? "Duplicate Fuel Types are not allowed in your Powertrain Warranties. Please update to a different Fuel Type."
            : "";
        });
        break;
      }
      default: {
        powertrainWarrantyFields.forEach(warranty => {
          if (warranty.fuelType) {
            valid =
              powertrainWarrantyFields.filter(
                item => item.fuelType === warranty.fuelType
              ).length == 1;
            errors["fuelType"] = !valid
              ? "Duplicate Fuel Types are not allowed in your Powertrain Warranties. Please update to a different Fuel Type."
              : "";
          }
          if (warranty.hourlyRate) {
            valid = parseFloat(warranty.hourlyRate) <= 999;
            errors["hourlyRate"] = !valid
              ? "The hourly rate cannot be greater than 999"
              : "";
          }
          if (warranty.baseCoverage) {
            valid =
              parseFloat(warranty.baseCoverage) <= 99 &&
              parseFloat(warranty.baseCoverage) > 0;
            errors["baseCoverage"] = !valid
              ? "The Base coverage cannot be greater than 99 or less than 1"
              : "";
          }
          if (warranty.mileageCoverage) {
            valid =
              parseFloat(warranty.mileageCoverage) <= 999999 &&
              parseFloat(warranty.mileageCoverage) > 0;
            errors["mileageCoverage"] = !valid
              ? "The Mileage coverage cannot be greater than 999999 or less than 1"
              : "";
          }
        });
      }
    }
    valid = valid && this.isValidForm();
    return { errors, valid };
  }

  getAddPayload() {
    const { newLaborPricing, powertrainWarrantyFields } = this.state;
    console.log("getAddPayload newLaborPricing---" + newLaborPricing)
    return {
      ...newLaborPricing,
      powertrainWarranties: JSON.stringify(powertrainWarrantyFields)
    };
  }

  saveHandler = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { errors, newLaborPricing } = this.state;
    console.log("saveHandler newLaborPricing---" + newLaborPricing)
    let isValid = true;
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    if (!isValid) {
      this.updateStatusBox(
        this.correctInvalidFielsError + " - " + this.getErrorStrings(errors),
        "error",
        false
      );
      return;
    }
    const data = this.getAddPayload();
    const url = `/ops/proxyapi/ddsproxy/rest/table/dealerLaborPricingRule${
      newLaborPricing.dealerLaborPricingRuleId
        ? `/${newLaborPricing.dealerLaborPricingRuleId}?_method=put`
        : ``
    }`;
    makeSecureRestApi(
      {
        url,
        method: "post",
        data
      },
      response => {
        if (response?.success) {
          this.updateStatusBox(this.savedMsg, "success", true);
          const { dealerLaborPricingRule } = response;
          this.props.updatePricingLaborAfterSave(
            dealerLaborPricingRule,
            newLaborPricing.dealerLaborPricingRuleId
          );
          this.setState({ dirty: false, valid: false }, () => {
            setTimeout(() => {
              this.props.hideSlider();
            }, 800);
          });
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  };

  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    return errCount !== 0;
  }
  getErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let msg = "";
    for (const value of iterator) {
      if (value && value !== "") {
        // In case of valid error string
        msg = msg + value + " ";
      }
    }
    return msg;
  }
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }

  render() {
    const { localeStrings } = this.context;
    const {
      newLaborPricing,
      payTypeOptions,
      rateTypeOptions,
      valid,
      powertrainWarrantyFields,
      fuelTypes,
      showDeleteConfirmation
    } = this.state;
    const showWarrantySelector = newLaborPricing.payType === "Warranty";
    const shorRateTypeSelector = newLaborPricing.payType === "Customer Pay";
    const showWarrantyDisabled = newLaborPricing.payType === "Service Contract";
    const showOEMFields = newLaborPricing.warrantyType === "OEM";
    if (newLaborPricing.make === "ANY"){
      //if make === ANY, set disabled of Warranty payTypeOption is true
      payTypeOptions.map(payTypeOption =>(payTypeOption.value === "Warranty"? payTypeOption.disabled = true: payTypeOption.disabled = false))
    }else{
      //if not, set disabled of Warranty payTypeOption is false
      payTypeOptions.map(payTypeOption => {if (payTypeOption.value === "Warranty"){return payTypeOption.disabled = false}} )
    }
    const powertrainWarrantyCards = powertrainWarrantyFields?.map(
      (warrantyField, index) => {
        const id = index;
        return (
          <AddPowertrainWarrantyForm
            key={`warranty-${id}`}
            field={warrantyField}
            index={index}
            fuelList={fuelTypes}
            extended={showOEMFields}
          />
        );
      }
    );

    let msg = this.saveWarrantyConfirmationMsg;
    const deleteModal = showDeleteConfirmation ? (
      <Confirmation
        htmlId="deleteLaborPricing"
        message={msg}
        proceedButtonStyle="danger"
        show={showDeleteConfirmation}
        actionFunction={() => {
          this.confirmSaveChanges();
        }}
        closeDialog={this.closeDeleteModal}
      />
    ) : (
      ""
    );
    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
    const editSection = (
      <React.Fragment>
        <div className="xmm-tab-bar">
          {statusBox}
          <Button
            htmlId="saveAction"
            buttonStyle="primary"
            disabled={!valid}
            onClick={this.openConfirmationModal}
          >
            {this.saveLabel}
          </Button>
        </div>
        <div className="xmm-slider-tab-scroll">
          <Card htmlId="addDiscounDetailsId">
            <div className="comment-form">
              <Grid htmlId="settingGrid">
                <Row className="show-grid">
                  <Col xs={6} md={6}>
                    <SelectInput
                      htmlId="payType"
                      name="payType"
                      label={localeStrings["xmm.portal.grid.pay_type"]}
                      options={payTypeOptions}
                      onChange={this.onChange}
                      value={newLaborPricing.payType}
                      displayDeselectOption={false}
                      disabled={newLaborPricing.dealerLaborPricingRuleId}
                    />
                  </Col>
                  <Col
                    xs={6}
                    md={6}
                    className={shorRateTypeSelector ? "" : "hide"}
                  >
                    <SelectInput
                      htmlId="rateType"
                      name="rateType"
                      label={localeStrings["xmm.portal.grid.rate_type"]}
                      options={rateTypeOptions}
                      onChange={this.onChange}
                      value={newLaborPricing.rateType}
                      displayDeselectOption={false}
                    />
                  </Col>
                  <Col
                    xs={6}
                    md={6}
                    className={showWarrantyDisabled ? "" : "hide"}
                  >
                    <SelectInput
                      htmlId="warrantyType"
                      name="warrantyType"
                      label={localeStrings["xmm.portal.grid.warranty_type"]}
                      options={[
                        {
                          label: "3rd Party",
                          value: "3rd Party"
                        }
                      ]}
                      onChange={this.onChange}
                      value="3rd Party"
                      displayDeselectOption={false}
                      disabled
                    />
                  </Col>
                  <Col
                    xs={6.5}
                    md={6.5}
                    className={showWarrantySelector ? "" : "hide"}
                  >
                    <SelectInput
                      htmlId="warrantyType"
                      name="warrantyType"
                      label={localeStrings["xmm.portal.grid.warranty_type"]}
                      options={[
                        {
                          label:
                            localeStrings["xmm.portal.pricing_rules.oem_factory_warranty"],
                          value: "OEM Factory Warranty"
                        },
                        {
                          label:
                            localeStrings["xmm.portal.pricing_rules.oem_extended_warranty"],
                          value: "OEM Extended Warranty"
                        },
                        {
                          label:
                            localeStrings["xmm.portal.pricing_rules.oem_maintenance_plan"],
                          value: "OEM Maintenance Plan"
                        },
                      ]}
                      onChange={this.onChange}
                      value={newLaborPricing.warrantyType}
                      displayDeselectOption={false}
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <TextInput
                      htmlId="planName"
                      name="planName"
                      label={localeStrings["xmm.portal.grid.plan_name"]}
                      onChange={this.onChange}
                      value={newLaborPricing.planName || ""}
                      required
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <TextInput
                      htmlId="planCode"
                      name="planCode"
                      label={localeStrings["xmm.portal.grid.plan_code"]}
                      onChange={this.onChange}
                      value={newLaborPricing.planCode || ""}
                      required
                      maxLength={3}
                    />
                  </Col>
                  <Row className="column-row">
                    <Col
                      xs={6}
                      md={6}
                      className={showWarrantyDisabled ? "" : "hide"}
                    >
                      <SearchableSelect
                        htmlId="warrantyVendor"
                        name="warrantyVendor"
                        label={localeStrings["xmm.portal.grid.warranty_vendor"]}
                        options={this.state.allVendorList}
                        onChange={this.onChange}
                        value={newLaborPricing.warrantyVendor}
                        displayDeselectOption={false}
                        enableMultiSelect={false}
                        maxHeight={280}
                      />
                    </Col>
                  </Row>
                  <Col xs={12} md={12}>
                    <TextInput
                      htmlId="laborRateDesc"
                      name="laborRateDesc"
                      label={localeStrings["xmm.portal.grid.labor_rate_desc"]}
                      disabled
                      onChange={this.onChange}
                      value={newLaborPricing.planName || ""}
                      appendChild={
                        <Badge htmlId="badgeDefault">
                          {newLaborPricing.code}
                        </Badge>
                      }
                    />
                  </Col>
                  <Row className="column-row">
                    <Col xs={6} md={6}>
                      <TextInput
                        htmlId="oem"
                        name="make"
                        label={localeStrings["xmm.portal.common.oem"]}
                        disabled
                        onChange={this.onChange}
                        value={newLaborPricing.make || ""}
                      />
                    </Col>
                    <Col xs={6} md={6}>
                      <NumericInput
                        htmlId="skillAStandaloneRate"
                        name="skillAStandaloneRate"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.grid.hourly_rate"
                          ]
                        }
                        inputPrefix="$"
                        onChange={this.onChange}
                        value={newLaborPricing.skillAStandaloneRate || ""}
                        maxValue={999}
                        required
                      />
                    </Col>
                  </Row>                  
                </Row>
              </Grid>
            </div>
          </Card>
        </div>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        {editSection}
        {deleteModal}
      </React.Fragment>
    );
  }
}

export default AddLaborPricingFormPage;

AddLaborPricingFormPage.propTypes = {
  rowRecord: PropTypes.object,
  hideSlider: PropTypes.func,
  updatePricingLaborAfterSave: PropTypes.func
};
/* eslint-enable no-console */
